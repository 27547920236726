export default function () {
  return [
    {
      author: "Новиков Николай",
      date: "14.04.2021",
      time: "14:00",
      action: "Добавил версию файла",
      new_value: "Версия 6",
    },
    {
      author: "Болатов Ерлан",
      date: "13.04.2021",
      time: "13:00",
      action: "Скачал файл",
    },
    {
      author: "Болатов Ерлан",
      date: "05.05.2021",
      time: "12:00",
      action: "Добавил версию файла",
      new_value: "Версия 4",
    },
    {
      author: "Болатов Ерлан",
      date: "05.05.20201",
      time: "11:00",
      action: "Удалил версию файла",
      new_value: "Версия 3",
    },
    {
      author: "Новиков Николай",
      date: "25.03.2021",
      time: "14:00",
      action: "Переименовал файл",
      new_value: "Чертеж 001",
      old_value: "Чертеж 1",
    },
    {
      author: "Болатов Ерлан",
      date: "23.03.2021",
      time: "12:00",
      action: "Добавил версию файла",
      new_value: "Версия 3",
    },
    {
      author: "Новиков Николай",
      date: "12.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Болатов Ерлан",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
    {
      author: "Арнольд Светлана",
      date: "01.03.2021",
      time: "12:00",
      action: "Добавил доступ пользователю",
      new_value: "Новиков Николай",
    },
  ];
}
